.footer {
	background-color:#2E2E2E;
	padding:80px 0 80px;
	position:relative;

	@include below($m){
		padding:30px 0;
	}
	&__logotype {
		height: 60px;
		display: flex;
		align-items: center;
		@include below($m){
			margin-bottom:20px;
			display:inline-block;
		}
	}

	&__wrap {
		max-width:1180px;
		width:100%;
		margin:0 auto;
		display:flex;
		flex-direction: row;
		justify-content: space-between;

		@include below(1200px){
			max-width:100%;
			padding:0 20px;
		}

		@include below($m){
			display:block;
			flex-direction: column;
		}
	}

	&__grid {
		display:flex;
		flex-direction: row;
		justify-content: space-between;

		@include below($m){
			display:block;
			flex-direction: column;
		}
	}

	&__logotype {
		&--height{
			display: flex;	
			align-items: center;
			height: 60px;
			margin-bottom: 0 !important;
			img{
				max-height: 25px;
			}
		}
	}

	&__companies {
		ul{
			display: flex;
			flex-wrap: wrap;
		}
		li {
			width: 30%;
			@include below($s){
				width: 50%;
			}
		}
	}

	&__column {
		width: 50%;
		display:flex;
		flex-direction: column;
		@include below($m){
			width:70%;
		}
		@include below($s){
			width:100%;
		}

		.contact {
			display:flex;
			flex-direction: column;
			margin-bottom:10px;

			a {
				color:#fff;
				font-size:rem(16px);
				line-height:rem(21px);
				text-decoration:none;
				font-family:$groteskregular;
				margin-bottom:10px;
				&:hover {
					text-decoration:underline;
				}
				&.mail{
					margin-bottom:20px;
				}
			}

			p {
				color:#fff;
				font-size:rem(16px);
				line-height:rem(16px);
				font-family:$groteskregular;
			}
		}
		.locations{
			display: flex;
			a{
				margin-right: 20%;
				@include below($s){
					margin-right: 10%;
				}
			}
			p{
				font-size: 2rem;
				margin-bottom: 1rem;
			}
		}

		.social {
			display:flex;
			flex-direction: row;
			margin-bottom:40px;
			
			@include below($s){
				margin-bottom:20px;
			}

			&__icon {
				width:40px;
				height:40px;
				background-size:20px 20px;
				background-position: center;
				background-repeat:no-repeat;
				transition:opacity .2s ease-in-out;
				border: 1px solid white;
				border-radius: 40px;

				&:hover {
					opacity:0.5;
				}

				&.linkedin {
					background-image:url('../img/icon-linkedin-white.svg');
					margin-right:10px;
				}

				&.facebook {
					background-image:url('../img/icon-facebook-white.svg');
					margin-right:10px;
				}
				&.instagram {
					background-image:url('../img/icon-instagram-white.svg');
					margin-right:10px;
				}
			}
		}
	}

	&__copyright {
		position:absolute;
		bottom:40px;
		color:#fff;
		font-family:$groteskregular;
		font-size:rem(16px);
		line-height:rem(52px);

		@include below($m){
			position:static;
			line-height:rem(16px);
		}
	}
}

.google-maps {
	position:relative;
	width:420px;
	height:350px;

	@include below($m){
		margin-top:20px;
		margin-bottom:20px;
		width:100%;
	}

	@include below($s){
		margin-top:0;
	}

	iframe {
		position:absolute;
		top:0;
		left:0;
		width:100% !important;
		height:100% !important;
	}
}

.footer-map {
	height: 350px;
	display: inline-block;

	img {
		width: 100%;
		display: block;
	}

	@include below($m) {
		width: 100%;
		height: auto;
		margin: 20px 0;
		display: block;
	}
}