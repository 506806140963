@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';
@import 'fonts';
@import 'swiper';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

$red: #F04B3C;
$yellow: #FFAF37;

$rem-baseline: 10px;

html {
	@include rem-baseline;


	@media (max-width: 1280px) {
		@include rem-baseline(90%);

	}

	@media (max-width: 800px) {
		@include rem-baseline(65%);
	}

	@media (max-width: 500px) {
		@include rem-baseline(75%);
		text-size-adjust: none;
	}
}

body {
	background-color:#F7F7F7;
	color:#2E2E2E;
	font-family:$genathregular;

	&.logged-in {
		.midnight-container {
			top:32px !important;
		}
	}

	@include below($s){
		padding-top:59px;
	}
}

.wrapper {
	max-width:1180px;
	width:100%;
	margin:0 auto;

	@include below(1220px){
		padding:0 20px;
		max-width:100%;
	}
}

.grid-overflow-top {
	margin-top:-100px;

	@include below(1220px){
		margin-top:-50px;
	}
	@include below($s){
		margin-top:-40px;
		z-index:7;
		position:relative;
	}
}

.grid-overflow-bottom {
	margin-bottom:-100px;

	@include below($s){
		margin-bottom:-40px;
	}
}

.grid-2 {
	display:grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 20px;
	grid-row-gap:20px;
	margin-bottom:20px;

	@include below($s){
		grid-template-columns:repeat(1, 1fr);
	}
}

.grid-masonry {
	@include above($m){
		display:grid;
		grid-template-areas:
				"b a a"
				"b a a"
				"b c c";
		grid-template-columns: 40.677% auto auto;
		grid-auto-flow: dense;
		align-items:end;
		grid-row-gap: 20px;
		grid-column-gap: 20px;
		margin-bottom:20px;
	}

	@include below($m){
		.grid-item {
			margin-bottom:20px;
		}
	}

	@include above($m){
		.grid-item:nth-child(1){
			grid-area:b;
			min-height:650px;

			.news-card__inner, .article-card__inner, .speak-card__inner {
				@include align-both(absolute);
				width:100%;
				padding:60px;

				@include below($s){
					padding:30px;
				}
			}
		}

		.grid-item:nth-child(2){
			grid-area:a;
			min-height:500px;

			.news-card__inner, .article-card__inner, .speak-card__inner {
				@include align-both(absolute);
				width:100%;
				padding:60px;

				@include below($s){
					padding:30px;
				}
			}
		}

		.grid-item:nth-child(3){
			grid-area:c;

			.news-card__inner, .article-card__inner, .speak-card__inner {
				position:static;
				transform:none;
				width:100%;
				padding:60px;


				@include below($s){
					padding:30px;
				}
			}
		}
	}

	&--reversed {
		@include above($m){
			grid-template-areas:
			"a a b"
			"c c b"
			"c c b";
			grid-template-columns: auto auto 40.677%;
			align-items:start;
		}

		@include above($m){
			.grid-item:nth-child(1){
				grid-area:b;
				min-height:650px;

				.news-card__inner, .article-card__inner, .speak-card__inner {
					@include align-both(absolute);
					width:100%;
					padding:60px;

					@include below($s){
						padding:30px;
					}
				}
			}
		}

		@include above($m){
			.grid-item:nth-child(2){
				grid-area:a;
				min-height:auto;
				.news-card__inner, .article-card__inner, .speak-card__inner {
					position:static;
					transform:none;
					width:100%;
					padding:60px;


					@include below($s){
						padding:30px;
					}
				}
			}
		}

		@include above($m){
			.grid-item:nth-child(3){
				grid-area:c;
				min-height:500px;

				.news-card__inner, .article-card__inner, .speak-card__inner {
					@include align-both(absolute);
					width:100%;
					padding:60px;

					@include below($s){
						padding:30px;
					}
				}
			}
		}
	}
}


.grid-full-width {
	display:grid;
	grid-template-columns: repeat(1, 1fr);
	margin-bottom:20px;
}

.grid-overflow-hidden {
	overflow:hidden;
}

.overlay {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color:rgba(0, 0, 0, .7);
	z-index:98;
	opacity:0;
	pointer-events:none;
	transition:opacity .4s ease-in-out;

	&.visible {
		opacity:1;
		pointer-events:auto;
	}
}

@import 'components/navigation';
@import 'components/sub-menu';
@import 'components/sidebar-navigation';
@import 'components/video-hero';
@import 'components/button';
@import 'components/speak-card';
@import 'components/article-card';
@import 'components/link-card';
@import 'components/job-listing';
@import 'components/slider';
@import 'components/news-card';
@import 'components/contact-block';
@import 'components/big-speak';
@import 'components/development-header';
@import 'components/article-listing';
@import 'components/link-listing';
@import 'components/team';
@import 'components/thoughtpiece-card';
@import 'components/breadcrumbs';
@import 'components/post-navigation';
@import 'components/article';
@import 'components/footer';
@import 'components/chatbot';
@import 'components/list';