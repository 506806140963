.slider {
	position:relative;

	@include below($s){
		overflow:hidden;
	}
	&__slides {
		position:relative;
		height:900px;
		overflow: hidden;

		@include below($s){
			height:calc(100vh - 59px);
		}
	}

	&__slide {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		opacity:0;
		transition:opacity 1s ease-in-out;
		background-size:1440px 900px;
		background-position:center center;
		background-repeat:no-repeat;

		@include below($s) {
			background-size: 109%;
			background-position: 90px 0;
		}

		&.visible {
			opacity:1;

			.slider__slide__content {
				transform:translateX(0);
				opacity:1;
				transition-delay: .6s;
			}
		}

		&__inner {
			max-width:1180px;
			margin:0 auto;
			display:flex;
			flex-direction: row;
			justify-content: flex-end;
			height:900px;
			@include below($s){
				height:calc(100vh - 59px);
			}
		}

		&__content {
			width:50%;
			justify-content: center;
			align-self:center;
			transform:translateX(50px);
			opacity:0;
			padding-left:20px;
			transition:transform .6s ease-in-out, opacity .6s ease-in-out;

			@include below($s){
				width:100%;
				padding:0 20px;
				position: absolute;
				bottom: 0;
				padding-bottom: 130px;
			}

			.sub-title {
				font-size:rem(20px);
				line-height:rem(32px);
				font-family:$groteskregular;
				margin-bottom:rem(20px);
				display:block;
				color:#fff;
			}

			.title {
				font-size:rem(72px);
				line-height:rem(64px);
				letter-spacing:-0.72px;
				margin-bottom:rem(20px);
				color:#fff;
				margin-bottom: 15px;

				strong {
					font-family:$groteskmedium;
					font-weight: 100;
					line-height: 0;
				}

				@include below($s) {
					font-size:5rem;
					line-height:4.5rem;
				}
			}

			p {
				font-size:rem(20px);
				line-height:rem(28px);
				font-family:$groteskregular;
				color:#fff;
			}
		}

		&__image {
			width:50%;
			justify-content: center;
			align-self:center;
			padding-right:20px;
			img {
				width:100%;
				height:auto;
			}
		}
	}

	&__controls {
		display:flex;
		flex-direction: row;
		position:absolute;
		bottom:40px;
		flex-wrap:wrap;
		width:100%;
		justify-content: center;
		align-content:center;

		p {
			align-self:center;
			font-size:12px;
			color:#fff;
			font-family:$groteskregular;
			margin:0 20px;
		}

		.control {
			width:40px;
			height:40px;
			background-size:40px 40px;
		}

		.next {
			background-image:url('../img/icon-slider-next.svg');
		}

		.prev {
			background-image:url('../img/icon-slider-prev.svg');
		}
	}
}