.contact-block {
	background-color:$red;
	height:673px;
	display: flex;

	background-repeat:no-repeat;
	background-size:1180px 673px;
	background-position:center center;

	@include below($s) {
		background-size:cover;
		height: auto;
		padding: 0;
	}

	&__inner {
		align-self:center;
		max-width:1180px;
		width:100%;
		margin:0 auto;

		@include below(1200px){
			padding:0 20px;
		}

		@include below($s){
			padding:50px 20px;
		}
	}

	&__sub-title {
		font-size:rem(18px);
		line-height:rem(18px);
		color:#fff;
		margin-bottom:15px;
		display:block;
		font-family:$groteskregular;

		@include below($s){
			//text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
		}
	}

	&__title {
		font-size:rem(56px);
		line-height:rem(50px);
		letter-spacing:-0.63px;
		margin-bottom:27px;
		color:#fff;

		strong {
			font-family:$groteskmedium;
			font-weight: 100;
		}

		@include below($s) {
			font-size:5rem;
			line-height:4.5rem;
			//text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
		}
	}

	br {
		@include below($s){
			display:none;
		}
	}

	p {
		font-size:rem(20px);
		line-height:rem(28px);
		color:#fff;
		font-family:$groteskregular;
		margin-bottom:36px;

		@include below($s){
			//text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
			max-width: 70%;
		}
	}
}

.cblock-author {
	color: #fff;
	margin-bottom: 30px;

	h3.c-white, span.c-white {
		color: #fff;
	}
}